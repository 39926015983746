<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="8">
        <b-card footer-tag="footer">
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <b-row>
              <b-col md="8">
                <b-form @submit.stop.prevent="submitSearch">
                  <b-input-group>
                    <b-input-group-prepend is-text
                      ><i class="fa fa-search"></i
                    ></b-input-group-prepend>
                    <b-form-input
                      v-model="search"
                      placeholder="Хайх"
                    ></b-form-input>
                    <b-input-group-append
                      ><b-button variant="primary" type="submit"
                        >Хайх</b-button
                      ></b-input-group-append
                    >
                    <b-input-group-append
                      ><b-button variant="secondary" @click="reset"
                        ><i class="fa fa-refresh"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
              </b-col>
              <b-col md="3">
                <button class="btn btn-primary" @click="add">Нэмэх</button>
              </b-col>
            </b-row>
          </div>
          <div class="mb-3">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Категори</th>
                  <th>Салбар</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(operator, index) in operator_list" :key="index">
                  <td>{{ index + pagination.from }}</td>
                  <td>{{ operator["type_name"] | decrypt }}</td>
                  <td>{{ operator["merchant_name"] | decrypt }}</td>
                  <td>{{ operator["email"] | decrypt }}</td>
                  <td>
                    <button
                      href=""
                      class="btn btn-primary btn-sm"
                      @click="selectOperator(operator)"
                    >
                      Засах
                    </button>
                    <button
                      href=""
                      class="btn btn-danger btn-sm ml-1"
                      @click="deleteOperator(operator.id)"
                    >
                      Устгах
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > 20">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="20"
                v-model="page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card footer-tag="footer">
          <loading
            :active.sync="isLoadingForm"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <h4 v-if="selected_operator['id']">
            Засах
          </h4>
          <h4 v-else>
            Шинээр нэмэх
          </h4>
          <hr />
          <form @submit="saveOperator">
            <div class="form-group">
              <label for="">Төрөл</label>
              <select
                type="email"
                class="form-control"
                required
                v-model="form.type_id"
              >
                <option :value="null" selected disabled>Сонгоно уу</option>
                <option
                  :key="index"
                  v-for="(item, index) in type_list"
                  :value="item['id'] | decrypt"
                >
                  {{ item["name"] | decrypt }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Мерчант</label>
              <input
                type="text"
                class="form-control"
                required
                v-model="form.merchant_name"
              />
            </div>
            <div class="form-group">
              <label for="">И-мэйл</label>
              <input
                type="email"
                class="form-control"
                required
                v-model="form.email"
              />
            </div>
            <div class="form-group">
              <label for="">Нууц үг</label>
              <input
                type="password"
                class="form-control"
                required
                v-model="form.password"
              />
            </div>
            <button type="submit" class="btn btn-primary text-center">
              Хадгалах
            </button>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: function() {
    return {
      // xyp_domain: "http://localhost:5000",
      xyp_domain: "https://xyp.numur.mn",
      isLoadingForm: false,
      isLoading: false,
      search: "",
      page: 1,
      contractCount: 0,
      description: "",
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "date",
        },
        { label: "Гэрээний дугаар", key: "contractNumber" },
        { label: "Тоо", key: "contractCount" },
        { label: "Үүсгэсэн ажилтан", key: "createdBy" },
        { label: "Тайлбар", key: "description" },
        { label: "File", key: "contractFile" },
      ],
      operator_list: [],
      all_operator_list: [],
      selected_operator: {
        id: null,
        email: null,
      },
      form: {
        email: "",
        password: "",
        type_id: null,
        merchant_name: "",
      },
      type_list: [],
    };
  },
  created() {
    this.getTypeList();
    this.load_data();
  },
  watch: {
    page: {
      handler(page) {
        this.load_data();
      },
      deep: true,
    },
  },
  methods: {
    add: function() {
      this.$data.selected_operator = {
        id: null,
        email: null,
        type_id: null,
        merchant_name: "",
      };
    },
    selectOperator: function(_op) {
      this.$data.selected_operator = _op;
      this.$data.form.email = this.decrypt(_op["email"]);
      this.$data.form.password = null;
      this.$data.form.merchant_name = this.decrypt(_op["merchant_name"]);
      this.$data.form.type_id = this.decrypt(_op["type_id"]);
    },
    reset: function() {
      this.$data.operator_list = this.$data.all_operator_list;
    },
    submitSearch: function() {
      this.load_data();
    },
    saveOperator: function() {
      if (this.$data.selected_operator.id) {
        this.updateOperator();
      } else {
        this.createOperator();
      }
    },
    getTypeList: function() {
      this.isLoadingForm = true;
      this.$http
        .get(this.$data.xyp_domain + "/getlogintypelist.php", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoadingForm = false;
            this.$data.type_list = response.body;
          },
          (response) => {
            this.isLoadingForm = false;
          }
        );
    },
    createOperator: function() {
      this.isLoadingForm = true;
      this.$http
        .get(this.$data.xyp_domain + "/createoperator.php", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
          params: {
            op_email: this.encrypt(this.$data.form.email),
            op_pass: this.encrypt(this.$data.form.password),
            op_type: this.encrypt(this.$data.form.type_id),
            op_merchant: this.encrypt(this.$data.form.merchant_name),
          },
        })
        .then(
          (response) => {
            this.isLoadingForm = false;
            var result = parseInt(this.decrypt(response.data.response));
            if (result) {
              this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
              this.load_data();
            } else {
              this.showToast("Анхаар", "Хадгалахад алдаа гараа", "warning");
            }
          },
          (response) => {
            this.isLoadingForm = false;
          }
        );
    },
    updateOperator: function() {
      this.isLoadingForm = true;
      this.$http
        .get(this.$data.xyp_domain + "/updateoperator.php", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
          params: {
            op_id: this.$data.selected_operator.id,
            op_email: this.encrypt(this.$data.form.email),
            op_pass: this.encrypt(this.$data.form.password),
            op_type: this.encrypt(this.$data.form.type_id),
            op_merchant: this.encrypt(this.$data.form.merchant_name),
          },
        })
        .then(
          (response) => {
            this.isLoadingForm = false;
            var result = parseInt(this.decrypt(response.data.response));
            if (result) {
              this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
              this.load_data();
            } else {
              this.showToast("Анхаар", "Хадгалахад алдаа гараа", "warning");
            }
          },
          (response) => {
            this.isLoadingForm = false;
          }
        );
    },
    deleteOperator: function(_id) {
      var result = confirm("Устгахдаа итгэлэй байна уу?");
      if (result) {
        this.isLoading = true;
        this.$http
          .get(this.$data.xyp_domain + "/deleteoperator.php", {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
            params: {
              op_id: _id,
            },
          })
          .then(
            (response) => {
              this.isLoading = false;
              var result = parseInt(this.decrypt(response.data.response));
              if (result) {
                this.showToast("Амжилттай", "Амжилттай устгалаа", "success");
                this.load_data();
              } else {
                this.showToast("Анхаар", "Устгахад алдаа гараа", "warning");
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      }
    },
    load_data: function() {
      this.isLoading = true;

      this.$http
        .get(this.$data.xyp_domain + "/getlogindatalist.php", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
          params: {
            q: this.$data.search,
            page: this.$data.page,
          },
        })
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response);
            if (response.body.success) {
              this.$data.pagination.total = response.body.total;
              this.$data.pagination.from = response.body.from;
              this.$data.pagination.to = response.body.to;
              this.$data.operator_list = response.body.datas;
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
